<template>
  <div class="drawStation">
    <div class="input">
      <div>
        <span>关键字搜索：</span>
        <el-input style="margin-right:0" id="tipinput" v-model="tipinput" placeholder="请输入" size="mini"></el-input>
        <span class="tips">（点击地图进行位置确定）</span>
      </div>
    </div>
    <div id="draw-station-container"></div>
  </div>
</template>

<script>
import { key, code } from '@/enum/dict.js'
import AMapLoader from '@amap/amap-jsapi-loader'
import { isEmpty } from '@/utils'
window._AMapSecurityConfig = {
  securityJsCode: code // '「申请的安全密钥」',
}
export default {
  name: 'Map',
  props: {
    lat: {
      type: [String, Number],
      default: ''
    },
    lng: {
      type: [String, Number],
      default: ''
    },
    province: {
      type: [String],
      default: ''
    },
    city: {
      type: [String],
      default: ''
    },
    district: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      // 地图对象
      AMap: null,
      // 地图实例对象
      map: null,
      // 经纬度
      location: {
        lat: '',
        lng: ''
      },
      // 搜索输入
      tipinput: '',
      auto: null,
      placeSearch: null
    }
  },
  watch: {
    lat: {
      // immediate:true,
      handler(newV, oldV) {
        // this.initMap()
      }
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    // this.initMap()
  },
  methods: {
    /**
     * 创建地图
     */
    initMap() {
      AMapLoader.load({
        key: key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Geocoder'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then(AMap => {
          this.AMap = AMap
          this.map = new AMap.Map('draw-station-container', {
            resizeEnable: true,
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 15, //初始化地图级别
            center: [this.lng, this.lat] //初始化地图中心点位置
            // mapStyle: 'amap://styles/macaron', //设置地图的显示样式
          })

          // 地图控件
          this.map.addControl(new AMap.Scale())
          // 搜索框自动完成类
          this.auto = new AMap.AutoComplete({
            input: 'tipinput'
          })
          //构造地点查询类
          this.placeSearch = new AMap.PlaceSearch({
            map: this.map
          })

          // 当选中某条搜索记录时触发
          this.auto.on('select', this.select)
          // poi覆盖物点击事件
          this.placeSearch.on('markerClick', this.clickMarkerHandler)
          // 地图点击事件
          this.map.on('click', this.clickMapHandler)
          //1.点击地图任意位置生成一个marker
          let iconEnd = new AMap.Icon({
            size: new AMap.Size(25, 34),
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
            imageSize: new AMap.Size(135, 40),
            imageOffset: new AMap.Pixel(-95, -3)
          })
          this.marker = new AMap.Marker({
            position: new AMap.LngLat(this.lng, this.lat),
            icon: iconEnd
          })
          this.map.add(this.marker)
          this.map.setFitView()
        })
        .catch(e => {
          console.log(e)
        })
    },

    // 当选中某条记录时会触发
    select(e) {
      const { lat, lng } = e.poi.location
      this.$emit('update:lat', lat)
      this.$emit('update:lng', lng)
      this.getLngLatService(lat, lng)
      this.placeSearch.setCity(e.poi.adcode)
      this.placeSearch.search(e.poi.name) //关键字查询查询
    },

    // 点击地图事件
    clickMapHandler(e) {
      this.location.lng = e.lnglat.getLng()
      this.location.lat = e.lnglat.getLat()
      this.$emit('update:lat', e.lnglat.getLat())
      this.$emit('update:lng', e.lnglat.getLng())
      this.getLngLatService(e.lnglat.getLat(), e.lnglat.getLng())
      this.$message.success('选择成功')
    },
    // 点击poi覆盖物事件
    clickMarkerHandler(e) {
      this.location.lng = e.data.location.lng
      this.location.lat = e.data.location.lat
    },
    // 确定拾取坐标点
    // handleDrawStation() {
    //   if (isEmpty(this.location.lng) || isEmpty(this.location.lat)) {
    //     return this.$message.error('请先拾取坐标点')
    //   }
    //   this.$emit('selectLocation', this.location)
    // }
    //逆向地理编码服务
    getLngLatService(lat, lng) {
      let pos = [lng, lat]
      let lnglat = new AMap.LngLat(lng, lat)
      let geocoder = new AMap.Geocoder({
        // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        city: '全国'
      })
      if (this.marker) this.map.remove(this.marker)
      //1.点击地图任意位置生成一个marker
      let iconEnd = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-95, -3)
      })
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(lng, lat),
        icon: iconEnd
      })
      this.map.add(this.marker)

      let address = ''
      //2.将位置转化为坐标点-->地理信息
      //3.根据地理信息（地址）进行搜索获取详细信息！
      geocoder.getAddress(lnglat, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          address = result.regeocode.formattedAddress
          console.log(result.regeocode.addressComponent, '@@')
          let res = {
            pos: pos,
            address: address
          }
          const { province, city, district } = result.regeocode.addressComponent
          this.$emit('update:province', province)
          this.$emit('update:city', city)
          this.$emit('update:district', district)
          //需求：固定的窗体信息进行展示！
          // bus.$emit('shareAddressDetails', res)
        } else {
          this.$message.error('根据经纬度查询地址失败')
        }
      })
    }
  },
  beforeDestroy() {
    // 销毁地图
    if (this.map) this.map.destroy()
  }
}
</script>

<style lang="scss" scoped>
.drawStation {
  .tips {
    font-size: 12px;
    color: #606266;
  }
  #draw-station-container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 400px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .el-input {
      width: 150px;
      margin-right: 20px;
    }
  }
}
</style>
<style lang="scss">
.amap-sug-result {
  z-index: 9999;
  visibility: visible;
}
</style>
